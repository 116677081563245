import {
  BatteryDischargingIcon,
  BatteryFullIcon,
  BatteryLowIcon,
  Icon,
  IconProps,
} from '@arena-labs/strive2-ui';
import { getBatteryStatus } from '@strive/wearable';

type StrivewareBatteryProps = Omit<IconProps, 'as'> & {
  level: number;
};
export function StrivewareBattery({ level, ...props }: StrivewareBatteryProps) {
  const batteryStatus = getBatteryStatus(level);
  const Svg = {
    low: BatteryLowIcon,
    medium: BatteryDischargingIcon,
    high: BatteryFullIcon,
  }[batteryStatus];

  const color = {
    low: 'error.500',
    medium: 'warning.500',
    high: 'success.500',
  }[batteryStatus];

  return <Icon as={Svg} color={color} {...props} />;
}
