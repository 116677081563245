import { createContext, useContext } from 'react';
import { useSelector } from '@xstate5/react';
import { Actor } from 'xstate5';

import { assert } from '@strive/utils';
import { strivewarePairingMachine } from '@strive/wearable';

type StrivewareSetupActor = Actor<typeof strivewarePairingMachine>;
export const StrivewareSetupContext =
  createContext<StrivewareSetupActor | null>(null);

export function useSetupActor() {
  const actor = useContext(StrivewareSetupContext);
  assert(actor, 'StrivewareSetupContext not found');
  return actor;
}

export function useDiscoveredDevices() {
  const actor = useSetupActor();
  return useSelector(actor, (state) => state.context.devices);
}
