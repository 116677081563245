import React from 'react';
import { Flex, IconButton, Image, Text } from '@chakra-ui/react';

import { ArrowUp, BackLink } from '@arena-labs/strive2-ui';

import { useSetupActor } from './hooks';
import { SetupBranding } from './setup-branding';

type SetupIntroProps = {
  onBack?: React.MouseEventHandler<HTMLAnchorElement>;
};

export function SetupIntro({ onBack }: SetupIntroProps) {
  const setupActor = useSetupActor();
  const onContinue = () => setupActor.send({ type: 'Next' });

  return (
    <Flex
      direction="column"
      px="6"
      pt="calc(env(safe-area-inset-top) + var(--chakra-space-4))"
      pb="calc(env(safe-area-inset-bottom) + var(--chakra-space-4))"
      gap="6"
      h="full"
    >
      <Flex gap="4" align="center">
        <BackLink href="/" color="white" onClick={onBack} />
        <SetupBranding />
      </Flex>

      <Image
        my="auto"
        src="/images/striveware/striveware-power-case.webp"
        alt="StriveWare with case"
        mx="auto"
      />
      <Text textStyle="h1" as="h1" letterSpacing="-1">
        <div>Connect Wirelessly,</div>
        <div>Stay in Sync.</div>
      </Text>

      <IconButton
        isRound
        aria-label="continue"
        icon={<ArrowUp />}
        onClick={onContinue}
        ml="auto"
        transform="rotate(90deg)"
        boxSize="12"
      />
    </Flex>
  );
}
