import { useMemo } from 'react';
import {
  Button,
  Flex,
  ListItem,
  Text,
  UnorderedList,
  useDisclosure,
} from '@chakra-ui/react';

import { LottieFile } from '@arena-labs/strive2-ui';

import { ContactModal } from '../../../support/contact-modal';
import { useSetupActor } from './hooks';

export function SetupNoDevices() {
  const setupActor = useSetupActor();
  const animation = useMemo(
    () => import('./scanning-for-devices.lottie.json'),
    [],
  );
  const helpDisclosure = useDisclosure();

  return (
    <Flex
      direction="column"
      gap="3"
      px="6"
      py="4"
      pb="calc(env(safe-area-inset-bottom) + var(--chakra-space-4))"
      h="full"
    >
      <Text textStyle="h1" as="h1">
        Unable to locate your StriveWare
      </Text>

      <LottieFile as={animation} boxSize="280px" my="auto" alignSelf="center" />

      <Text textStyle="p1" color="neutral.100">
        Still not pairing? Try this:
      </Text>
      <UnorderedList ml="8" textStyle="p2" spacing="2" color="neutral.100">
        <ListItem>Turn off your phone bluetooth and turn it on again.</ListItem>
        <ListItem>
          Check if the ring has battery by showing the green light.
        </ListItem>
      </UnorderedList>
      <Button
        mt="auto"
        variant="outline"
        onClick={() => helpDisclosure.onOpen()}
      >
        Strive Support
      </Button>
      <Button
        variant="solid"
        onClick={() => setupActor.send({ type: 'Start again' })}
      >
        Try Again
      </Button>
      <ContactModal {...helpDisclosure} />
    </Flex>
  );
}
