// DeviceHeader.tsx
import React from 'react';
import { Text } from '@chakra-ui/react';

import { BackLink, StatusBar } from '@arena-labs/strive2-ui';

import { AppBanner } from '../../layout/app-banner';

export type StrivewareHeaderProps = {
  label: string;
  backHref?: string;
  onClickBack?: React.MouseEventHandler<HTMLAnchorElement>;
};
export function StrivewareHeader({
  label,
  backHref = '/',
  onClickBack,
}: StrivewareHeaderProps) {
  return (
    <AppBanner
      sticky
      bg="white"
      shadow="none"
      alignItems="center"
      borderBottom="1px solid"
      borderColor="black"
    >
      <StatusBar color="white" style="light" />
      <BackLink href={backHref} color="black" onClick={onClickBack} />
      <Text textStyle="h3" as="h1" color="black">
        {label}
      </Text>
    </AppBanner>
  );
}
