import { useMemo } from 'react';
import { Button, Flex, ListItem, OrderedList, Text } from '@chakra-ui/react';
import { match, P } from 'ts-pattern';
import { waitFor } from 'xstate5';

import { BackLink, LottieFile } from '@arena-labs/strive2-ui';
import { openSettingsPage } from '@strive/notifications';
import { StrivewareContext, StrivewareSelector } from '@strive/wearable';

import { useSetupActor } from './hooks';
import { SetupBranding } from './setup-branding';

export function SetupCharging() {
  const strivewareActor = StrivewareContext.useActorRef();
  const state = StrivewareContext.useSelector(
    StrivewareSelector.bluetoothState,
  );

  const setupActor = useSetupActor();

  const onStartScanning = async () => {
    if (state === 'permission.prompt') {
      strivewareActor.send({ type: 'Request Permissions' });
      const snapshot = await waitFor(
        strivewareActor,
        (state) =>
          StrivewareSelector.bluetoothState(state) !== 'permission.prompt',
      );
      if (snapshot.matches('Bluetooth Ready')) {
        setupActor.send({ type: 'Next' });
      }
    } else if (state === 'bluetooth.ready') {
      setupActor.send({ type: 'Next' });
    }
  };

  const animation = useMemo(() => import('./charge-device.lottie.json'), []);

  return (
    <Flex
      direction="column"
      px="6"
      pt="calc(env(safe-area-inset-top) + var(--chakra-space-4))"
      pb="calc(env(safe-area-inset-bottom) + var(--chakra-space-4))"
      gap="6"
      h="full"
    >
      <Flex gap="4" align="center">
        <BackLink
          href="/"
          color="white"
          onClick={(event) => {
            event.preventDefault();
            setupActor.send({ type: 'Back' });
          }}
        />
        <SetupBranding />
      </Flex>

      {match(state)
        .with(P.union('permission.prompt', 'bluetooth.ready'), () => (
          <>
            <Text textStyle="h1" as="h1">
              Charge your StriveWare before initial pairing.
            </Text>
            <Text textStyle="p2">
              Keep Bluetooth on for real-time insights with StriveWare.
            </Text>
            <LottieFile
              as={animation}
              boxSize="246px"
              my="auto"
              alignSelf="center"
            />
            <Button onClick={onStartScanning} mt="auto">
              Start
            </Button>
          </>
        ))
        .with('permission.denied', () => (
          <>
            <Text textStyle="h1" as="h1">
              Enable Bluetooth permissions
            </Text>
            <Text textStyle="p2">
              To complete the pairing process and access data, please allow
              Bluetooth permission in your phone settings.
            </Text>
            <Text textStyle="p2">
              Follow these steps to enable Bluetooth permission for Arena
              Strive:
            </Text>
            <OrderedList ml="4" spacing="2" textStyle="p2">
              <ListItem>Go to Settings on your phone.</ListItem>
              <ListItem>Select Arena Strive from the list of apps.</ListItem>
              <ListItem>
                Allow Arena Strive to access Bluetooth by toggling the switch to
                ON.
              </ListItem>
            </OrderedList>
            <Button onClick={() => openSettingsPage()} mt="auto">
              Open Settings
            </Button>
          </>
        ))
        .with('bluetooth.off', () => (
          <>
            <Text textStyle="h1" as="h1">
              Enable Bluetooth
            </Text>
            <Text textStyle="p2">
              To complete the pairing process and access data, please enable
              Bluetooth in your phone settings.
            </Text>
          </>
        ))
        .exhaustive()}
    </Flex>
  );
}
